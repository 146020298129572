import React from 'react';
import { RiYoutubeLine } from "react-icons/ri";
import { GrGamepad } from "react-icons/gr";


const  ProjectBox = ({projectPhoto, projectName}) => {
  const desc = {
    //Space Cargo//
    SpaceCargoProjectname: "Space Cargo",
    SpaceCargoMultiplayer:"",
    SpaceCargoDesc : "In Cargo Game, pilot a futuristic space freighter through perilous cosmic environments. Collect power-ups: Discover various power-ups scattered throughout the levels, granting you temporary boosts in firepower, defense, or agility.",
    SpaceCargoYoutube:"https://www.youtube.com/watch?v=daEyVR_HQuA",
    SpaceCargoWebsite:"https://cargoserie.com/cargo/cargo-le-jeu/",
    SpaceCargoWebsite1:"",
    
    //Huak Tuah Runner//
    huaktuahProjectname: "Huak Tuah Runner",
    huaktuahMultiplayer:"",
    huaktuahDesc : "Race through an endless runner game featuring the iconic Huak Tuah girl! Collect Huak Tuah coins as you dash through vibrant levels, aiming for the highest score. The better you perform, the more chances you have to earn free Huak Tuah crypto coins, which can be transferred directly to your wallet. Join the fun and make every run count!",
    huaktuahYoutube:"",
    huaktuahWebsite:"https://huaktuah-runner.vercel.app/",
    huaktuahWebsite1:"https://www.huaktuahcoin.com/huak-tuah-game",

    //Space Cargo//
    TadeDuckProjectname: "Tade Games : The Running Duck",
    TadeDuckMultiplayer:"",
    TadeDuckDesc : "In this fast-paced endless runner game, players take control of a lively duck dashing through vibrant city streets. Navigate around obstacles, leap over barriers, and collect shiny coins while dodging oncoming traffic. As you race through the bustling environment, the duck's agility and speed will be put to the test. Unlock fun power-ups and stylish outfits to keep the adventure fresh and exciting. How far can you go before the duck meets its match?",
    TadeDuckYoutube:"https://www.youtube.com/watch?v=_hPqZrxKaGE",
    TadeDuckWebsite:"",
    TadeDuckWebsite1:"",

    //Tube it or loose it//
    TubeitorlooseitProjectname: "Tube it or loose it",
    TubeitorlooseitMultiplayer:"Multiplayer",
    TubeitorlooseitDesc : "Tube It or Lose It is a thrilling 1 vs. 3 party game where one player battles against three others in a high-stakes arena. The solo player’s goal is to destroy the carts of the three opposing players using a variety of attacks and tactics. Meanwhile, the trio must work together to evade the solo player's onslaught, using strategic maneuvers and defensive abilities to stay alive.",
    TubeitorlooseitYoutube:"https://youtu.be/tgSwCAfUmkQ",
    TubeitorlooseitWebsite:"",
    TubeitorlooseitWebsite1:"",

    //Auris The Game//
    AurisProjectname: "Auris The Game",
    AurisMultiplayer:"",
    AurisDesc : "AURIS is JACK RABBIT'S Journey into the Light. He has escaped from “Domestic Violence” and has entered into the enchanted forest of the unknown. He needs your help to venture through it and keep going on his adventure into the light.",
    AurisYoutube:"https://www.youtube.com/watch?v=YT2koq79oAo",
    AurisWebsite:"https://play.google.com/store/apps/details?id=com.Auris.AurisTheGame",
    AurisWebsite1:"",

    //Auris The Game 2//
    Auris2Projectname: "Auris The Game 2",
    Auris2Multiplayer:"",
    Auris2Desc : "Full of rage against the machine Jack Rabbit is willing to fight the evil empire. JACK RABBIT comes full of wisdom from THE SACRED LIAR but also full of rage against the machine as he now knows how much THEY LIE just to keep us blind away from truth.",
    Auris2Youtube:"",
    Auris2Website:"https://www.gameloop.com/game/casual/auris-the-game-2-on-pc",
    Auris2Website1:"",

    //Vrunner//
    VrunnerProjectname: "Endless Vrunner",
    VrunnerMultiplayer:"",
    VrunnerDesc : "Embark on a stylish adventure in this unique endless runner game. Dash through vibrant environments, avoiding dirt hurdles and slithering snakes to keep your shirts pristine. Compete globally on the leaderboard, unlock achievements. It's a fast-paced, chic adventure that blends fashion and fun.",
    VrunnerYoutube:"",
    VrunnerWebsite:"",
    VrunnerWebsite1:"",

    //ShipYard Manager//
    ShipyardProjectname: "ShipYard Manager",
    ShipyardMultiplayer:"",
    ShipyardDesc : "Take control over ships, your goal is to safely dock as much ships as possible. Become the best coordinator of all times. Beat you own high score, be BETTER by every try! Upgrade your dock → Gain more space for ships → Earn more coins Be the best Shipyard Manager! Take over traffic",
    ShipyardYoutube:"https://www.youtube.com/watch?v=b5ybRfleOIE",
    ShipyardWebsite:"",
    ShipyardWebsite1:"",

    //Pump Action//
    PumpactionProjectname: "Pump Action",
    PumpactionMultiplayer:"Multiplayer",
    PumpactionDesc : "Prepare yourself for an adrenaline-charged, heart-pounding battle royale third-person shooter sensation! Play fast-paced and timed multiplayer and multi-team showdowns. Captivating maps, from gritty industrial arenas to breathtaking grasslands.",
    PumpactionYoutube:"https://www.youtube.com/watch?v=yigYxdhUYTE",
    PumpactionWebsite:"",
    PumpactionWebsite1:"",

    //Heavy Driver//
    HeavydriverProjectname: "Heavy Driver",
    HeavydriverMultiplayer:"Multiplayer",
    HeavydriverDesc : "It is a 3rd person vehicle-combat game. This game has 3 different maps. The player can play solo or with friends (Squad vs Squad). Player can also add friends. In the lobby or during game, players can also be able to chat with each other. There are about 5 different types of vehicles. They can also buy new vehicles using coins.",
    HeavydriverYoutube:"https://www.youtube.com/watch?v=KZPT-pg1z2w",
    HeavydriverWebsite:"https://kashirao.itch.io/heavy-driver",
    HeavydriverWebsite1:"",
    //Pixel Quest//
    PixelquestProjectname: "Pixel Quest",
    PixelquestMultiplayer:"Multiplayer",
    PixelquestDesc : "Embark on the ultimate pixelated adventure in 'Pixel Quest: Dynamic Encounters,'. Choose wisely from a diverse cast, each tailored for specific terrains across 5 unique maps – snowy expanses, mysterious caves, lush jungles, towering mountains, and scorching deserts. Devising a unique strategy for each level by selecting characters with powers perfectly matched to the situation.",
    PixelquestYoutube:"https://www.youtube.com/watch?v=2Igb3k3htcc",
    PixelquestWebsite:"",    
    PixelquestWebsite1:"",    


    //Tiny Fighters//
    TinyProjectname: "Tiny Fighters",
    TinyMultiplayer:"Multiplayer",
    TinyDesc : "Tiny Fighters is an action-packed, miniature combat game where players control unique pint-sized warriors in intense one-on-one battles within dynamic arenas. Choose from a diverse roster of Tiny Fighters, each with distinct abilities, and engage in strategic, fast-paced combat. Challenge friends in multiplayer mode, climb the ranks, and prove your skills in this adrenaline-fueled showdown of tiny proportions.",
    TinyYoutube:"https://www.youtube.com/watch?v=LVM0fXL4a4s",
    TinyWebsite:"",  
    TinyWebsite1:"",  

    //Bubble Shooter Candy//
    BubbleProjectname: "Bubble Shooter Candy",
    BubbleMultiplayer:"",
    BubbleDesc : "Bubble Shooter Candy, you'll have to pop your way through hundreds of levels, using your skills and strategy to clear the board and rescue the trapped baby animals. With a variety of different candy bubbles to choose from, and power-ups to help you along the way, Bubble Shooter Candy is sure to keep you entertained for hours on end.  ",
    BubbleYoutube:"",
    BubbleWebsite:"https://play.google.com/store/apps/details?id=com.GameCrypto.NFTBubbleShooter",  
    BubbleWebsite1:"",

    //Match 3 Champion//
    Match3Projectname: "Match 3 Champion",
    Match3Multiplayer:"",
    Match3Desc : "Welcome to Match 3 Champion, a captivating puzzle game that will immerse you in a world of delightful challenges. Engage in a visually enchanting experience as you swap, match, and crush vibrant gems and jewels in this classic match-three adventure. With hundreds of levels to conquer, each more enticing than the last, you'll find yourself addicted to the intuitive gameplay and mesmerizing animations.",
    Match3Youtube:"",
    Match3Website:"https://play.google.com/store/apps/details?id=com.GameCrypto.NFTMatch3",  

    //Block Champion//
    BlockProjectname: "Block Champion",
    BlockDesc : "Welcome to the world of Block 3 Champion, a thrilling puzzle game that transforms the classic match-three concept into a captivating block-matching adventure. Immerse yourself in a vibrant realm filled with colorful blocks and engaging challenges. Swap, match, and crush blocks to conquer a myriad of levels, each offering unique puzzles and strategic opportunities. ",
    BlockYoutube:"",
    BlockWebsite:"https://play.google.com/store/apps/details?id=com.GameCrypto.NFTBlocks",  

    //Sudoko Champion//
    SudokoProjectname: "Sudoko Champion",
    SudokoMultiplayer:"Multiplayer",
    SudokoDesc : "Welcome to Sudoku Champion, the ultimate destination for puzzle enthusiasts seeking a challenging and rewarding experience. Immerse yourself in the timeless world of Sudoku, where numbers align to create a symphony of logic and strategy. With varying difficulty levels, from novice to expert, Sudoku Champion caters to players of all skill levels. Enjoy an intuitive interface, elegant design, and a vast array of puzzles that will put your logical reasoning to the test.",
    SudokoYoutube:"",
    SudokoWebsite:"https://play.google.com/store/apps/details?id=com.GameCrypto.NFTSudoku",   
   
    //Runner Champion//
    RunnerProjectname: "Runner Champion",
    RunnerMultiplayer:"",
    RunnerDesc : "Embark on an endless adventure in Runner Champion, a heart-pounding endless runner set in the heart of a dense and mysterious jungle. As the player, navigate through an ever-changing landscape, dodging obstacles, and engaging in epic battles with alien invaders. Armed with the formidable power of fire, unleash scorching attacks to overcome extraterrestrial foes that lurk in the shadows. How far can you run, how many enemies can you defeat, and will you emerge as the true Runner Champion?",
    RunnerYoutube:"",
    RunnerWebsite:"https://play.google.com/store/apps/details?id=com.wade.chibiendlessrunner", 
    RunnerWebsite1:"",  

    //Sheep Gone Mad//
    SheepProjectname: "Sheep's Gone Mad",
    SheepMultiplayer:"",
    SheepDesc : "Sheep's Gone Mad is a fast-paced action game where you, a brave sheep, defend against waves of cunning wolves and foxes with a powerful machine gun. Strategically place various machine guns around the fence using earned coins to protect your flock. Face increasingly challenging foes, collect coins for upgrades, and unleash a barrage of firepower to keep the invaders at bay. Can you lead your sheep to safety and become the ultimate defender in this woolly war?",
    SheepYoutube:"https://www.youtube.com/watch?v=72O7fUWFkVc",
    SheepWebsite:"",
    SheepWebsite1:"",

    //Color Defender//
    ColorProjectname: "Color Defender",
    ColorMultiplayer:"",
    ColorDesc : "Color Defender is a vibrant tower defense game where you must strategically place the right color in each row to repel oncoming colorful enemies. Match hues with precision to defend your tower, and as you conquer each wave, you inch closer to becoming the ultimate defender. Quick thinking and color coordination are your weapons in this visually dynamic challenge. Can you withstand the relentless assault and emerge victorious in the world of Color Defender?",
    ColorYoutube:"https://www.youtube.com/watch?v=iI2WAYiJuj0",
    ColorWebsite:"",   
    ColorWebsite1:"",

    //Paint Game//
    PaintProjectname: "Protocolos Bomberos",
    PaintMultiplayer:"",
    PaintDesc : "App aimed at simulating easily and graphically the different protocols of the Fire Department, as well as recreating interventions to be able to explain and visualize them better. It allows you to simulate any situation since it is possible to draw the scenario and the vehicles both in profile view (flat fire, garage fire, warehouse fire...) and in plan view (traffic accidents, industrial accidents...).",
    PaintYoutube:"",
    PaintWebsite:"https://play.google.com/store/apps/details?id=com.javizgz.ProtocolosBomberos&pli=1",  
    PaintWebsite1:"",   


    //Tade Duck//
    DuckProjectname: "The Duck Road",
    DuckMultiplayer:"",
    DuckDesc : "This game has 5 unique duck heroes and explores 10 maps with themes like Jungle, Ice, Desert, and Sci-Fi Robo. Each map offers different enemies and challenges, from ancient predators in the Jungle to high-tech foes in Sci-Fi Robo. Use your duck's special abilities to overcome obstacles and conquer enemies in this thrilling adventure!",
    DuckYoutube:"https://www.youtube.com/watch?v=F5SdpZnkN1s&t=16s",
    DuckWebsite:"https://play.google.com/store/apps/details?id=com.Jontadeo.TadeGamesTheDuckRoad",  
    DuckWebsite1:"",

    //Pop IT//
    PopitProjectname: "POP IT",
    PopitMultiplayer:"",
    PopitDesc : "Pop It is a fast-paced bubble popping game where you must burst as many bubbles as possible within a limited time. With three modes - Easy, Medium, and Hard - there's a challenge for everyone. Sharpen your reflexes, aim, and pop away to become the ultimate bubble bursting champion!",
    PopitYoutube:"",
    PopitWebsite:"",  
    PopitWebsite1:"https://apps.apple.com/us/app/pop-it-controller/id6479946919?platform=ipad",

    //Catz Game//
    CatzProjectname: "Jungle Catz",
    CatzMultiplayer:"Multiplayer",
    CatzDesc : "Battle your way through a Cast of colorful characters with unique abilities. Take a stance against the NinjAnts, while dodging deadly robots. Evade guitar playing SkullE's and block staff attacks from 'MonkFU' Monkeys! Go it alone, or grab 3 other homies for Co-op!",
    CatzYoutube:"https://cdn.cloudflare.steamstatic.com/steam/apps/256999982/movie480_vp9.webm?t=1708710347",
    CatzWebsite:"https://store.steampowered.com/app/2825140/Jungle_CATZ/?beta=1",  
    CatzWebsite1:"", 

    //Mario Party//
    PartygameProjectname: "Pushy Penguins",
    PartygameMultiplayer:"Multiplayer",
    PartygameDesc : "Pushy Penguin is a multiplayer game where you dodge penguins trying to push you into icy waters. Compete with friends to see who can survive the longest on the slippery ice floes. Can you outsmart your opponents and stay dry, or will you take the plunge?",
    PartygameYoutube:"https://youtu.be/j_5sb3cTFow",
    PartygameWebsite:"",
    PartygameWebsite1:"",

  }

  let show ='';
  let show1 ='';
  let show2='';
  let show3 ='';
  if(desc[projectName + 'Youtube']===""){
    show="none";
  }
  if(desc[projectName + 'Website']===""){
    show1="none";
  }

  if(desc[projectName + 'Multiplayer']===""){
    show2="none";
  }
  if(desc[projectName + 'Website1']===""){
    show3="none";
  }
    
  return (
    <div className='projectBox'> 
        <img className='projectPhoto' src={projectPhoto} alt="Project display" /> 
        <div>
            <br />
            <div className='projectname'>
              <h3>{desc[projectName + 'Projectname']}</h3>
            </div>

            <div className='multiplayertag' style={{display:show2}}>
              {desc[projectName + 'Multiplayer']}
            </div>
            <br />

            <p className='projectdesc'>
            {desc[projectName + 'Desc']}
            </p>
            
            <br />

            <a style={{display:show}} href={desc[projectName + 'Youtube']} target='_blank'>
              <button className='projectbtn'><RiYoutubeLine/> Demo</button>
            </a>

            <a style={{display:show1}} href={desc[projectName + 'Website']} target='_blank'>
              <button className='projectbtn'><GrGamepad /> Play </button>
            </a>

            <a style={{display:show3}} href={desc[projectName + 'Website1']} target='_blank'>
              <button className='projectbtn'><GrGamepad /> Play </button>
            </a>
        </div>
    </div>
  )
}

export default  ProjectBox