import React from 'react';
import Tilt from 'react-parallax-tilt';
import Lottie from "lottie-react"
import Coder from '../LottieFiles/promotion.json';
import ReactPlayer from 'react-player/youtube';


const About = () => {
  return (
    <>
      <div className='AboutPage'>
        <div className='AboutText'>
          <h1 className='AboutTextHeading' >Promote Your Brand with <b>Custom Games</b>.</h1>
          <p>
            Step up your marketing game by leveraging the power of interactive entertainment! We specialize in creating custom games that put your product, company, or brand front and center. Whether you’re looking to promote a crypto coin, stuffed toys, merchandise, memes, or any other unique offering, we can transform it into the star of an engaging game. <br /><br />

            In today's world, traditional ads are losing their impact. Our innovative approach allows you to reach and resonate with your audience by immersing them in a fun, interactive experience. By featuring your product as the main character or theme within the game, you’ll not only capture attention but also foster deeper engagement, build a community, and create lasting impressions. <br /> <br />


          </p>
        </div>

        <div>
          <Tilt>
            <Lottie
              className="illustration"
              animationData={Coder}
              loop={true}
            />
          </Tilt>
        </div>

      </div>

      <h1 className='SkillsHeading'><b>Promoted Products</b> through <b>Custom Games</b></h1>

      {/* First Product */}

      <div className='AboutPage'>
        <div className='AboutText'>
          <h1 className='AboutTextHeading' ><b>Huak Tuah Runner</b></h1>
          <p>
            We’re excited to showcase Huak Tuah Coin Runner, a custom endless runner game we developed to promote our client’s innovative cryptocurrency, Huak Tuah Coin. This project exemplifies our expertise in crafting tailor-made gaming experiences that not only entertain but also align perfectly with our clients' branding and promotional goals. <br /> <br />
            In Huak Tuah Coin Runner, players embark on a thrilling journey, avoiding obstacles and enemies while collecting Huak Tuah Coins along the way. We’ve designed the game to keep players engaged with dynamic gameplay and special features like the magnet power-up, which attracts coins from all directions, enhancing the gaming experience. <br /> <br />
            A standout feature of this custom game is the integration with the Phantom wallet. The player who claims the top spot on the global leaderboard can redeem their earned coins directly in their Phantom wallet, merging the excitement of gaming with real-world cryptocurrency rewards. <br /> <br />
            Our work on Huak Tuah Coin Runner highlights our ability to create custom games that not only captivate audiences but also drive meaningful engagement with your brand. Whether you’re looking to promote a product, service, or digital asset, our team of professionals is ready to develop a unique gaming experience that delivers results and leaves a lasting impression.
          </p>
        </div>

        <div>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <a
              href="https://huaktuah-runner.vercel.app/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: '#7055A1',
                textDecoration: 'none',
                fontSize: '20px',
                fontWeight: 'bold',
                padding: '10px 20px',
                border: '2px solid #7055A1',
                borderRadius: '5px',
                display: 'inline-block',
                transition: 'background-color 0.3s, color 0.3s'
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = '#7055A1';
                e.currentTarget.style.color = '#FFFFFF';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = 'transparent';
                e.currentTarget.style.color = '#7055A1';
              }}
            >
              Check out the Huak Tuah Game
            </a>
          </div>
              
          <br></br>
          <br></br>
          <br></br>
          
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <a
              href="https://www.huaktuahcoin.com/huak-tuah-game"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: '#7055A1',
                textDecoration: 'none',
                fontSize: '20px',
                fontWeight: 'bold',
                padding: '10px 20px',
                border: '2px solid #7055A1',
                borderRadius: '5px',
                display: 'inline-block',
                transition: 'background-color 0.3s, color 0.3s'
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = '#7055A1';
                e.currentTarget.style.color = '#FFFFFF';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = 'transparent';
                e.currentTarget.style.color = '#7055A1';
              }}
            >
              Visit their Website.
            </a>
          </div>
        </div>

      </div>

      {/* Second Product */}
      
      <div className='AboutPage'>
        <div className='AboutText'>
          <h1 className='AboutTextHeading' ><b>Tade Runner</b></h1>
          <p>
          We’re thrilled to introduce Duck Adventure, a custom side-scrolling game developed for a popular YouTuber with over 10 million subscribers. This unique game showcases a series of adorable ducks, each based on stuffed toys that our client is excited to bring to his audience.<br /> <br />
          In Duck Adventure, players embark on an epic journey across multiple levels, each featuring a distinct environment filled with challenging enemies. The game’s main character is a brave duck, but as you progress, you’ll unlock more than five different ducks, each with their own special attacking abilities. These diverse ducks allow players to experience the game in new ways with each level, keeping the gameplay fresh and exciting. <br /> <br />
          Every level in Duck Adventure offers a unique setting, from lush forests to icy tundras, ensuring that players are constantly engaged with new challenges and visuals. The game’s vibrant design and playful characters not only provide hours of entertainment but also serve as a perfect introduction to the client’s line of stuffed toys, making it an ideal promotional tool. <br /> <br />
          Our work on Duck Adventure demonstrates our commitment to creating custom games that align perfectly with our clients’ branding and marketing goals. Whether you’re a content creator looking to engage your audience or a brand aiming to promote your products, we’re here to develop a gaming experience that captivates and converts.
          </p>
        </div>

        <div>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <a
              href="https://play.google.com/store/apps/details?id=com.Jontadeo.TadeGamesTheDuckRoad"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: '#7055A1',
                textDecoration: 'none',
                fontSize: '20px',
                fontWeight: 'bold',
                padding: '10px 20px',
                border: '2px solid #7055A1',
                borderRadius: '5px',
                display: 'inline-block',
                transition: 'background-color 0.3s, color 0.3s'
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = '#7055A1';
                e.currentTarget.style.color = '#FFFFFF';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = 'transparent';
                e.currentTarget.style.color = '#7055A1';
              }}
            >
              Check out the Tade Runner
            </a>
          </div>
              
          <br></br>
          <br></br>
          <br></br>

          <div>
          <ReactPlayer url="https://www.youtube.com/watch?v=F5SdpZnkN1s&t=16s" />
        </div>
          
        </div>

      </div>

    
      {/* Third Product */}
      
      <div className='AboutPage'>
        <div className='AboutText'>
          <h1 className='AboutTextHeading' ><b>Mini Games For Museum</b></h1>
          <p>
            We’re proud to present Crimes of Passion, a custom-designed interactive experience we developed exclusively for the Los Angeles Crime Museum. This project highlights our expertise in creating tailored gaming solutions that engage and educate audiences.<br /> <br />
            Crimes of Passion is a series of mini-games where players step into the role of a forensic expert, solving thrilling cases like identifying a murderer, uncovering the poison used in a crime, and piecing together clues to determine who the victim’s killer is. These games are integrated into the museum exhibits, offering a unique, hands-on experience that captivates visitors of all ages. <br /> <br />
            Our work on Crimes of Passion is a testament to our commitment to crafting custom-made games that align perfectly with our clients' visions. Whether you’re looking to engage visitors in an educational setting or create an unforgettable interactive experience, our team of professionals is ready to bring your ideas to life. Let us help you create a custom game that stands out and leaves a lasting impact.
          </p>
        </div>

        <div>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <a
              href="https://www.crimesofpassioninteractive.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: '#7055A1',
                textDecoration: 'none',
                fontSize: '20px',
                fontWeight: 'bold',
                padding: '10px 20px',
                border: '2px solid #7055A1',
                borderRadius: '5px',
                display: 'inline-block',
                transition: 'background-color 0.3s, color 0.3s'
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = '#7055A1';
                e.currentTarget.style.color = '#FFFFFF';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = 'transparent';
                e.currentTarget.style.color = '#7055A1';
              }}
            >
              Visit the Crimes of Passion Interactive Museum Website
            </a>
          </div>
          <br></br>
          <br></br>
          <br></br>
          <ReactPlayer url="https://youtu.be/6eisCiZbwpw" />
        </div>

      </div>

      
      {/* Forth  Product */}
      
      <div className='AboutPage'>
        <div className='AboutText'>
          <h1 className='AboutTextHeading' ><b>Rappy Run</b></h1>
          <p>
            Rappy Run is an exhilarating custom game developed exclusively for a popular Japanese game show, designed to captivate audiences with its dynamic gameplay and charming protagonist. Featuring Rappy, a custom-designed rabbit character created specifically for this game, Rappy Run offers a unique blend of excitement, strategy, and entertainment, making it a standout experience on both gaming consoles and web platforms.<br /> <br />
            What sets Rappy Run apart is its integration into a live game show format. Players participate in real-time, competing against each other or working together to achieve high scores and complete challenges. The live audience adds an extra layer of excitement, as their reactions and interactions influence the game's flow, creating a dynamic and unpredictable experience. <br /> <br />
            With its vibrant graphics, engaging gameplay, and the charm of its custom rabbit character, Rappy Run is more than just a game—it's an immersive experience that brings joy to both players and viewers alike. Whether on a gaming console or the web, Rappy Run offers hours of entertainment, combining the thrill of competition with the creativity and innovation that defines the best of Japanese game shows.

          </p>
        </div>

        <div>
          <ReactPlayer url="https://youtu.be/4WIt0Ke1Um4" />
          <br></br> <br></br>
          <ReactPlayer url="https://youtu.be/6GkhQR7YBXs" />
        </div>
      </div>
    </>
  )
}

export default About