import React from 'react';
import ProjectBox from './ProjectBox';
import Bubble from '../images/TindogImage.png';
import Auris2 from '../images/Auris2.jpg';
import Vrunner from '../images/Vrunner.png';
import Pixelquest from '../images/PixelQuest.png';
import runner from '../images/Runner.jpg';
import space from '../images/SpaceCargo.png';
import auris from '../images/Auris.jpg';
import Shipyard from '../images/ShipyardManager.png';
import Pumpaction from '../images/PumpAction.png';
import Heavydriver from '../images/HeavyDriver.png';
import Tinyfighters from '../images/TinyFighters.png';
import Sheepgonemad from '../images/SheepGoneMad.png';
import ColorDefender from '../images/ColorDefender.png';
import Paintgame from '../images/Paintgame.png';
import Duck from '../images/TadeDuck.png';
import Popit from '../images/popit.jpg';
import catzgame from  '../images/catzgame.jpg';
import pushy from  '../images/pushy.png';
import tube from  '../images/tubeit.png';
import tade from  '../images/tade.png';
import huak from  '../images/huak.png';

const Projects = () => {
  return (
    <div>
      <h1 className='projectHeading'>Our <b>Portfolio</b></h1>
      <div className='project'>
        <ProjectBox projectPhoto={catzgame} projectName="Catz"/>
        <ProjectBox projectPhoto={tube} projectName="Tubeitorlooseit"/>
        <ProjectBox projectPhoto={tade} projectName="TadeDuck"/>
        <ProjectBox projectPhoto={huak} projectName="huaktuah"/>
        <ProjectBox projectPhoto={pushy} projectName="Partygame"/>
        <ProjectBox projectPhoto={space} projectName="SpaceCargo"/>
        <ProjectBox projectPhoto={Pumpaction} projectName="Pumpaction"/>
        <ProjectBox projectPhoto={Duck} projectName="Duck"/>
        <ProjectBox projectPhoto={Pixelquest} projectName="Pixelquest"/>
        <ProjectBox projectPhoto={Paintgame} projectName="Paint"/>
        <ProjectBox projectPhoto={Shipyard} projectName="Shipyard"/>
        <ProjectBox projectPhoto={Vrunner} projectName="Vrunner"/>
        <ProjectBox projectPhoto={Heavydriver} projectName="Heavydriver"/>
        <ProjectBox projectPhoto={Popit} projectName="Popit"/>
        <ProjectBox projectPhoto={auris} projectName="Auris"/>
        <ProjectBox projectPhoto={Tinyfighters} projectName="Tiny"/>
        <ProjectBox projectPhoto={Auris2} projectName="Auris2"/>
        <ProjectBox projectPhoto={Sheepgonemad} projectName="Sheep"/>
        <ProjectBox projectPhoto={ColorDefender} projectName="Color"/>
        <ProjectBox projectPhoto={runner} projectName="Runner"/>
      </div>

    </div>
  )
}

export default Projects