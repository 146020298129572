import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import Tilt from 'react-parallax-tilt';
import contact from "../LottieFiles/Contact us.json";
import Lottie from "lottie-react";


// npm i @emailjs/browser

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
          "service_2slcvyc",
          "template_vsf9nxp",
          form.current,
          "cQqfc3TpxotwYHDvC"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (

    <div className="contactpage">
        
   <div>
        <Tilt>
            <Lottie
                className="illustration"
                animationData={contact}
                loop={true}
           />
        </Tilt>

    </div>
          <div className="conatctform">
            <form className="contact-form" ref={form} onSubmit={sendEmail}>
            <h1>Contact Us</h1>
              <br/>
              <br/>  
              <label>Name</label>
              <input type="text" name="user_name" />
              <label>Email</label>
              <input type="email" name="user_email" />
              <label>Message</label>
              <textarea className="textareaa" name="message" />
              <input type="submit" value="Send"/>
            </form>
          </div>
                 
    </div>
  );
};

export default Contact;
